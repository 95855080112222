<template>
  <v-app>
    <dashboard-core-app-bar  :DashboardName=DashBoardName />

    <dashboard-core-drawer />

    <dashboard-core-view />

     <dashboard-core-settings  @ChangeDashboardName="ChangeDashboardName"  />

  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Dashboard'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      DashBoardName:"",
      expandOnHover: false,
    }),
    methods:{
      ChangeDashboardName(item){
        //console.log('i received item'+libelle);
        this.DashBoardName = item.libelle;
       

      }
    }
  }
</script>
